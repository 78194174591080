import { EntregasCards } from "@/components/entregas/EntregasCards";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useIndexedDB } from "@/hooks/use-indexed";
import { useOcorrenciaStore } from "@/store/ocorrencia-store";
import { useEffect, useState } from "react";
import { eOcorrenciaID, eOcorrenciaStatus } from "@/types/ocorrencia";
import { Button } from "@/components/ui/button";
import { Loader2, RefreshCcw, WifiOff } from "lucide-react";
import { useOnlineStatus } from "@/hooks/use-online-status";
import { useToast } from "@/hooks/use-toast";
import { useBatchProcessing } from "@/hooks/use-batch-request";
import { ocorrenciaService } from "@/services/ocorrencia.service";

const Entregas: React.FC = () => {
  const [qtdNaoSync, setQtdNaoSync] = useState(0);
  const { toast } = useToast();
  const { getByMultipleIndexes, countByMultipleIndexes } =
    useIndexedDB("entregas");
  const {
    ocorrencias,
    filters,
    setOcorrencias,
    setFilter,
    getSelectedStatus,
    unsyncedOcorrencias,
  } = useOcorrenciaStore();
  const { processBatches, isLoading } = useBatchProcessing();
  const isOnline = useOnlineStatus();

  useEffect(() => {
    fetchEntregas();
  }, [filters]);

  const fetchEntregas = () => {
    getByMultipleIndexes(filters, {
      orderBy: "data_hora",
      direction: "desc",
    }).then((entregasIdb) => {
      setOcorrencias(entregasIdb);
    });
    countByMultipleIndexes([
      {
        indexName: "status",
        value: eOcorrenciaStatus.NAO_SINCRONIZADO,
      },
    ]).then((count) => {
      setQtdNaoSync(count);
    });
  };

  const handleSincronizar = async () => {
    if (!isOnline) {
      toast({
        description: (
          <div className="flex items-center gap-4">
            <WifiOff size={16} /> Sem conexão com a internet
          </div>
        ),
        variant: "warning",
      });
      return;
    }
    try {
      await processBatches(10);
      fetchEntregas();
    } catch (error: any) {
      ocorrenciaService.logs({
        error: error || null,
      });
    }
  };

  return (
    <div className="min-h-screen font-[family-name:var(--font-geist-sans)] bg-gray-100">
      <div className="space-y-6 pb-24">
        <div className="px-4 pt-4 space-y-8 bg-blue-500 shadow-md">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white">Entregas</h2>
            {/* <Button variant={"ghost"} className="text-white hover:bg-blue-600">
              <Filter size={20} />
            </Button> */}
          </div>
          <Tabs
            defaultValue="all"
            className="w-full"
            onValueChange={(value) =>
              setFilter({ indexName: "ocorrencia_id", value })
            }
          >
            <TabsList className="w-full bg-transparent h-auto text-gray-400 p-0">
              <TabsTrigger
                value="all"
                className="text-white/70 text-sm flex-1 gap-2 border-b-2 border-transparent data-[state=active]:border-b-2 data-[state=active]:border-b-white data-[state=active]:text-white"
              >
                Todos
              </TabsTrigger>
              <TabsTrigger
                value={eOcorrenciaID.ENTREGUE}
                className="text-white/50 text-sm flex-1 gap-2 border-b-2 border-transparent data-[state=active]:border-b-2 data-[state=active]:border-b-white data-[state=active]:text-white"
              >
                Entregues
              </TabsTrigger>
              <TabsTrigger
                value={eOcorrenciaID.NAO_ENTREGUE}
                className="text-white/50 text-sm flex-1 gap-2 border-b-2 border-transparent data-[state=active]:border-b-2 data-[state=active]:border-b-white data-[state=active]:text-white"
              >
                Não entregues
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <div className="w-full overflow-x-auto no-scrollbar">
          <div className="flex gap-2 px-4">
            <button
              onClick={() => setFilter({ indexName: "status", value: "all" })}
              className={`whitespace-nowrap px-4 py-1.5 rounded-full text-[12px] font-medium transition-colors border
                ${
                  getSelectedStatus("all")
                    ? "text-blue-500 border-blue-500 bg-white"
                    : "bg-white text-gray-900/40 border-gray-400/40"
                }`}
            >
              Todos
            </button>
            <button
              onClick={() =>
                setFilter({
                  indexName: "status",
                  value: eOcorrenciaStatus.NAO_SINCRONIZADO,
                })
              }
              className={`whitespace-nowrap px-4 py-1.5 rounded-full text-[12px] font-medium transition-colors border
                ${
                  getSelectedStatus(eOcorrenciaStatus.NAO_SINCRONIZADO)
                    ? "text-blue-500 border-blue-500 bg-white"
                    : "bg-white text-gray-900/40 border-gray-400/40"
                }`}
            >
              Não sincronizado ({qtdNaoSync})
            </button>
            {/* <button
              onClick={() =>
                setFilter({
                  indexName: "error",
                  value: true,
                })
              }
              className={`whitespace-nowrap px-4 py-1.5 rounded-full text-[12px] font-medium transition-colors border
                            ${
                              getSelectedStatus(eOcorrenciaStatus.SINCRONIZADO)
                                ? "text-blue-500 border-blue-500 bg-white"
                                : "bg-white text-gray-900/40 border-gray-400/40"
                            }`}
            >
              Erro
            </button> */}
            <button
              onClick={() =>
                setFilter({
                  indexName: "status",
                  value: eOcorrenciaStatus.SINCRONIZADO,
                })
              }
              className={`whitespace-nowrap px-4 py-1.5 rounded-full text-[12px] font-medium transition-colors border
                ${
                  getSelectedStatus(eOcorrenciaStatus.SINCRONIZADO)
                    ? "text-blue-500 border-blue-500 bg-white"
                    : "bg-white text-gray-900/40 border-gray-400/40"
                }`}
            >
              Sincronizado
            </button>
          </div>
        </div>
        {unsyncedOcorrencias().length ? (
          <div className="flex px-4">
            <Button
              disabled={isLoading}
              onClick={handleSincronizar}
              className="w-full bg-green-500 hover:bg-green-600 gap-2"
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Sincronizando...
                </>
              ) : (
                <>
                  <RefreshCcw size={16} />
                  Sincronizar
                </>
              )}
            </Button>
          </div>
        ) : (
          ""
        )}
        <div>
          {/* <div className="px-4 text-sm font-medium text-gray-500 pb-2">
            Entregas de hoje
          </div> */}
          <div className="space-y-4 px-4">
            {ocorrencias.length === 0 ? (
              <p className="text-gray-500">Nenhuma entrega encontrada</p>
            ) : (
              ocorrencias?.map((ocorrencia) => (
                <EntregasCards key={ocorrencia.id} {...ocorrencia} />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entregas;
