import { CreateOcorrenciaParams, ocorrenciaService } from "@/services/ocorrencia.service";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useIndexedDB } from "./use-indexed";
import { arrayBufferToBase64 } from "@/lib/utils";
import { eOcorrenciaStatus, OcorrenciaFormData } from "@/types/ocorrencia";
import { useToast } from "@/hooks/use-toast";

const API_TOKEN = "2|aWmi8kH0F5z4lA1n145C1jWCU1naX6qsCnJB41Y8844d70b0";
const API_BASE_URL = import.meta.env.VITE_API_URL;

const sendBatch = async (batch: CreateOcorrenciaParams[]) => {
  const response = await axios.post(
    `${API_BASE_URL}/integracao/mobile/documento/ocorrencia`,
    batch,
    {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      },
    }
  );
  return response.data;
};

export const useBatchProcessing = () => {
  const { getByMultipleIndexes, update } = useIndexedDB("entregas");
  const { toast } = useToast();
  const mutation = useMutation({
    mutationFn: sendBatch,
    onSuccess: (data, batch) => {
      updateEntregas(data, batch);
      sendLogs(batch);
    },
    onError: (error, batch) => {
      toast({
        title: "Erro ao sincronizar",
        description: error?.message,
        variant: "destructive",
      });
      sendLogs(batch, error);
    },
  });

  const updateEntregas = async (
    response: OcorrenciaFormData[],
    entregasNaoSincronizadas: OcorrenciaFormData[]
  ) => {
    response.forEach(async (entrega) => {
      const entregaIdb = entregasNaoSincronizadas.find(
        (e) => e.id === entrega.id
      );
      if (entrega.error) {
        await update({
          ...entregaIdb,
          status: eOcorrenciaStatus.SINCRONIZADO,
          message: entrega.message,
          error: true,
        });
      } else {
        const {
          tipo_documento_transporte,
          tipo_documento_viagem_transporte,
          viagem_transporte_id,
          documento_transporte_id,
          documento_ocorrencia_id,
        } = entrega;
        await update({
          ...entregaIdb,
          tipo_documento_transporte,
          tipo_documento_viagem_transporte,
          viagem_transporte_id,
          documento_transporte_id,
          documento_ocorrencia_id,
          status: eOcorrenciaStatus.SINCRONIZADO,
          message: "",
          error: false,
        });
      }
    });
  };

  const processBatches = async (batchSize = 50) => {
    const entregasNaoSincronizadas = await getEntregasNaoSincronizadas();
    for (let i = 0; i < entregasNaoSincronizadas.length; i += batchSize) {
      const batch = entregasNaoSincronizadas.slice(i, i + batchSize);

      console.log(`Enviando batch ${i / batchSize + 1}`);
      console.log(`BatchSize: ${batchSize}`);

      await mutation.mutateAsync(batch);
    }

    console.log("Todos os lotes foram processados.");
  };

  const getEntregasNaoSincronizadas = async () => {
    const entregasNaoSincronizadas = await Promise.all(
      (
        await getByMultipleIndexes([
          {
            indexName: "status",
            value: eOcorrenciaStatus.NAO_SINCRONIZADO,
          },
        ])
      ).map(async (entrega: CreateOcorrenciaParams) => formatEntrega(entrega))
    );
    return entregasNaoSincronizadas;
  };

  const formatEntrega = async (entrega: CreateOcorrenciaParams) => {
    const file = entrega.images.length
      ? ((await arrayBufferToBase64(
          entrega.images[0].imageData,
          entrega.images[0].imageType
        )) as string)
      : null;
    return {
      ...entrega,
      file,
    };
  };

  const sendLogs = async (entregas: CreateOcorrenciaParams[], error?: Error) => {
    const entregasComErros = entregas.filter(
      (entrega) => entrega?.error
    );
    if(entregasComErros.length) {
      ocorrenciaService.logs({
        body: entregasComErros,
        error: error || null,
      });
    }
  };

  return {
    processBatches,
    sendLogs,
    isLoading: mutation.isPending,
  };
};
