import axios from "axios";
import { OcorrenciaFormData } from "../types/ocorrencia";

const API_TOKEN = "2|aWmi8kH0F5z4lA1n145C1jWCU1naX6qsCnJB41Y8844d70b0";
const API_BASE_URL = import.meta.env.VITE_API_URL;

// https://api.zorte.com.br/api/integracao/mobile/documento/ocorrencia
// http://api.zorte.lc/api/integracao/mobile/documento/ocorrencia

export interface CreateOcorrenciaParams extends OcorrenciaFormData {
  file?: string;
  placa?: string;
}

export const ocorrenciaService = {
  async create(data: CreateOcorrenciaParams[]) {
    const response = await axios.post(
      `${API_BASE_URL}/integracao/mobile/documento/ocorrencia`,
      data,
      {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
      }
    );

    return response.data;
  },
  async logs(data: {body?: CreateOcorrenciaParams[], error?: any}) {
    const APP_VERSION = import.meta.env.VITE_VERSION;
    const response = await axios.post(
      `https://entrega-logs.vercel.app/api/logs`,
      {...data, version: APP_VERSION},
      {
        headers: {
          'x-api-key': `as4d89a4d848942342344984`,
        },
      }
    );

    return response.data;
  },
};
