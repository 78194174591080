import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  ocorrenciaSchema,
  OcorrenciaFormData,
  eOcorrenciaStatus,
  eOcorrenciaID,
} from "../types/ocorrencia";
import { useToast } from "./use-toast";
import { useOcorrenciaStore } from "@/store/ocorrencia-store";
import { useScannerStore } from "@/store/scanner-store";
import { ocorrenciaService } from "@/services/ocorrencia.service";
// // import { addOccurrence } from '@/services/indexed-db.service';
import { useOnlineStatus } from "./use-online-status";
import { useIndexedDB } from "./use-indexed";
import dayjs from "dayjs";
import { arrayBufferToBase64, clearNumber } from "@/lib/utils";
import { useLocation } from "./use-location";
import { useEffect } from "react";

interface UseOcorrenciaProps {
  databaseName: string;
}

export const useOcorrencia = ({ databaseName }: UseOcorrenciaProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { ocorrenciaPhoto, isLoading, resetOcorrenciaPhoto, setIsLoading } =
    useOcorrenciaStore();
  const { numeroDocumento, chave } = useScannerStore();
  const { add, update } = useIndexedDB("entregas");
  const { getFirst } = useIndexedDB("motoristas");
  const location = useLocation();
  const isOnline = useOnlineStatus();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<OcorrenciaFormData>({
    resolver: zodResolver(ocorrenciaSchema),
    defaultValues: {
      database_name: databaseName,
      numero_documento: numeroDocumento || "",
      observacoes: "",
      ocorrencia_id: eOcorrenciaID.ENTREGUE,
      error: false,
      message: "",
      file: "",
      dt_ocorrencia: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      hora_ocorrencia: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      status: eOcorrenciaStatus.NAO_SINCRONIZADO,
      chave: chave || "",
      DocumentoOcorrenciaInfoAdicional: {
        nome_recebedor: "",
        nome_embarcacao: "",
        dh_saida: "",
        dh_chegada: "",
        nome_motorista: "",
        placa_veiculo: "",
      },
    },
  });

  const onSubmit = async (data: OcorrenciaFormData) => {
    try {
      if (!ocorrenciaPhoto) {
        toast({
          title: "Erro",
          description: "É necessário anexar uma foto",
          variant: "destructive",
        });
        return;
      }

      setIsLoading(true);

      const responseIdb = await add({
        ...data,
        numero_documento: clearNumber(numeroDocumento),
        hora_ocorrencia: data.dt_ocorrencia,
        status: eOcorrenciaStatus.NAO_SINCRONIZADO,
        images: ocorrenciaPhoto ? [ocorrenciaPhoto] : [],
        latitude: location?.latitude,
        longitude: location?.longitude,
      });

      toast({
        title: "Sucesso",
        description: "Ocorrência registrada com sucesso",
        variant: "success",
      });

      if (isOnline) {
        const file =
          ocorrenciaPhoto &&
          ((await arrayBufferToBase64(
            ocorrenciaPhoto.imageData,
            ocorrenciaPhoto.imageType
          )) as string);
        ocorrenciaService
          .create([
            {
              ...data,
              id: responseIdb.id,
              numero_documento: clearNumber(numeroDocumento),
              hora_ocorrencia: data.dt_ocorrencia,
              file: file || "",
              latitude: location?.latitude,
              longitude: location?.longitude,
            },
          ])
          .then(
            (
              entregasZorte: OcorrenciaFormData[] & {
                error: boolean;
                message: string;
              }
            ) => {
              const error = hasError(entregasZorte);
              const message = getMessage(entregasZorte);

              if (error) {
                update({
                  ...responseIdb,
                  status: eOcorrenciaStatus.SINCRONIZADO,
                  message,
                  error,
                });
                ocorrenciaService.logs({
                  body: [
                    {
                      ...data,
                      id: responseIdb.id,
                      numero_documento: clearNumber(numeroDocumento),
                      hora_ocorrencia: data.dt_ocorrencia,
                      file: file || "",
                      latitude: location?.latitude,
                      longitude: location?.longitude,
                      message,
                      error,
                    },
                  ],
                });
              } else {
                const {
                  tipo_documento_transporte,
                  tipo_documento_viagem_transporte,
                  viagem_transporte_id,
                  documento_transporte_id,
                  documento_ocorrencia_id,
                } = entregasZorte[0];
                update({
                  ...responseIdb,
                  tipo_documento_transporte,
                  tipo_documento_viagem_transporte,
                  viagem_transporte_id,
                  documento_transporte_id,
                  documento_ocorrencia_id,
                  status: eOcorrenciaStatus.SINCRONIZADO,
                  message: "",
                  error: false,
                });
              }
            }
          );
      }

      reset();
      resetOcorrenciaPhoto();
      navigate(`/${databaseName}`);
    } catch (error: any) {
      toast({
        title: "Erro",
        description: error.message || "Erro ao registrar ocorrência",
        variant: "destructive",
      });
      ocorrenciaService.logs({
        error: error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const hasError = (
    entregasZorte: OcorrenciaFormData[] & {
      error: boolean;
      message: string;
    }
  ) => {
    return (
      (typeof entregasZorte === "object" && entregasZorte?.error) ||
      entregasZorte[0]?.error ||
      false
    );
  };

  const getMessage = (
    entregasZorte: OcorrenciaFormData[] & {
      error: boolean;
      message: string;
    }
  ) => {
    return (
      (typeof entregasZorte === "object" && entregasZorte?.message) ||
      entregasZorte[0]?.message ||
      ""
    );
  };

  useEffect(() => {
    (async () => {
      const { placa, nome } = await getFirst();
      setValue("DocumentoOcorrenciaInfoAdicional", {
        placa_veiculo: placa,
        nome_motorista: nome,
      });
    })();
  }, []);

  return {
    register,
    handleSubmit,
    setValue,
    errors,
    isLoading,
    ocorrenciaPhoto,
    resetOcorrenciaPhoto,
    getValues,
    control,
    onSubmit,
  };
};
